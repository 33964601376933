import { connect } from 'react-redux';
import { deleteDownloadedBook, downloadBook } from '../../actions/bookActions';
import { updatePagesVisible } from '../../actions/bookViewActions';
import Header from './Header';

const mapStateToProps = (state, ownProps) => {
    const books = navigator.onLine ? state.books : state.downloadedBooks;
    const isDownloaded = state.downloadedBooks.find(
        (book) => book.ID === state.book.ID
    )
        ? true
        : false;
    return {
        user: state.user,
        books,
        blms: state.blms,
        book: state.book,
        isDownloaded
    };
};

export const HeaderContainer = connect(mapStateToProps, {
    downloadBook,
    updatePagesVisible,
    deleteDownloadedBook
})(Header);
