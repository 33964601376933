import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function modeReducer(state = initialState.viewerMode, action) {
    switch (action.type) {
        case types.UPDATE_MODE_SUCCESS:
            return action.viewerMode;

        case types.USER_LOGOUT_SUCCESS:
            return initialState.viewerMode;

        default:
            return state;
    }
}
