/*
 *  Reducer for the user and user account
 */

import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function userReducer(state = initialState.user, action) {
    switch (action.type) {
        case types.USER_LOGIN_SUCCESS:
        case types.USER_UPDATE_SUCCESS:
        case types.USER_UPDATE_PASSWORD_SUCCESS:
            const lastCheckSession = Date.now();
            return {...state, ...action.user, lastCheckSession}
        case types.USER_LOGOUT_SUCCESS:
            return initialState.user;

        default:
            return state;
    }
}
