import * as types from './actionTypes';

export const saveAdvancedSearchFilter = (filters) => ({
    type: types.SAVE_ADVANCED_SEARCH_FILTERS,
    filters
});

export const clearAdvancedSearchFilter = () => ({
    type: types.CLEAR_ADVANCED_SEARCH_FILTERS
});
