/*
 * This reducer contains all the state for managing the book toolbar
 */

import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function bookToolbar(state = initialState.bookToolbar, action) {
    switch (action.type) {
        case types.USER_LOGOUT_SUCCESS:
            return initialState.bookToolbar;
        case types.BOOK_TOOLBAR_POINTING:
            return initialState.bookToolbar;
        case types.BOOK_TOOLBAR_NOTES:
            return {
                ...initialState.bookToolbar,
                allowNotes: action.allowNotes,
                pointing: false,
                pagesClassName: 'note-mode'
            };
        case types.BOOK_TOOLBAR_HIGHLIGHTING:
            return {
                ...initialState.bookToolbar,
                highlighting: action.highlighting,
                highlightColor: action.highlightColor,
                pointing: false,
                pagesClassName: `hl-mode-${action.highlightColor}`
            };
        case types.BOOK_TOOLBAR_ERASING:
            return {
                ...initialState.bookToolbar,
                erasing: action.erasing,
                pointing: false,
                pagesClassName: 'eraser-mode'
            };
        case types.BOOK_TOOLBAR_UNDERLINING:
            return {
                ...initialState.bookToolbar,
                underlining: action.underlining,
                pointing: false,
                pagesClassName: 'hl-underline'
            };
        case types.BOOK_TOOLBAR_STRIKING:
            return {
                ...initialState.bookToolbar,
                striking: action.striking,
                pointing: false,
                pagesClassName: 'hl-strikethrough'
            };
        case types.BOOK_TOOLBAR_UPDATE_MODE:
            return { ...state, bookToolbar: action.bookToolbar };
        case types.BOOK_TOOLBAR_MOVING:
            return {
                ...initialState.bookToolbar,
                moving: true,
                pointing: true,
                pagesClassName: 'move-mode'
            };
        default:
            return state;
    }
}
