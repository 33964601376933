import * as React from 'react';
import { Modal } from 'react-bootstrap';

class Loading extends React.Component {
    render() {
        return (
            <Modal show={this.props.show} className="loading-modal">
                <Modal.Body className="text-center" style={{ color: '#fff' }}>
                    <h1>{this.props.message || 'LOADING...'}</h1>
                    <i className="fa fa-spinner fa-spin fa-3x fa-fw" />
                </Modal.Body>
            </Modal>
        );
    }
}

export default Loading;
