import { Container, Nav, NavItem, Navbar } from 'react-bootstrap';
import { ToastContainer, ToastMessage } from 'react-toastr';
import {
    manualAjaxEnd,
    manualAjaxStart
} from '../../actions/ajaxStatusActions';
import {
    userLogout,
    userUpdatePW,
    userUpdateProfile
} from '../../actions/userActions';

import FabricUI from './FabricUI';
import FontAwesome from 'react-fontawesome';
import React from 'react';
import { StyledNavbar } from '../../constants/styledComponents';
import { connect } from 'react-redux';
import constants from '../../constants/constants';
import { hashHistory } from 'react-router';
import logo from '../../images/logo.png';
import { requireSignIn } from '../../routes';

const ToastMessageFactory = React.createFactory(ToastMessage.animation);

class BLMView extends React.Component {
    toastCont;

    constructor(props) {
        super(props);
        this.state = {};

        this.handleSelect = this.handleSelect.bind(this);
        this.theme = constants.themeProvider.activeTheme;
    }

    signOut() {
        this.props.userLogout();
        hashHistory.push('/login');
    }

    handleSelect(e) {
        switch (e) {
            case 2:
                this.signOut();
                break;
            default:
        }
    }

    render() {
        if (requireSignIn(this.props.user, this.props.location)) {
            return null;
        }

        return (
            <Container className="content" style={{}}>
                <StyledNavbar
                    css={{
                        'border-bottom-color': this.theme.primaryBackground
                    }}
                >
                    <Navbar>
                        <Navbar.Brand>
                            <img src={logo} alt="" />
                        </Navbar.Brand>
                    </Navbar>
                    <Navbar.Collapse>
                        <Nav pullRight onSelect={this.handleSelect}>
                            <NavItem className="main-header-item" eventKey={1}>
                                {this.props.user.First} {this.props.user.Last}
                            </NavItem>
                            <NavItem className="main-header-item" eventKey={2}>
                                <FontAwesome name="sign-out" />
                            </NavItem>
                        </Nav>
                    </Navbar.Collapse>
                </StyledNavbar>
                <FabricUI />
                {
                    // <BLM />
                }
                <ToastContainer
                    toastMessageFactory={ToastMessageFactory}
                    ref={(toast) => {
                        this.toastCont = toast;
                    }}
                    className="toast-top-right"
                />
            </Container>
        );
    }
}

/*<div
  key={ID}
  className={'blm-outer blm-outer-' + ID}
  style={{ zIndex: 100, position: 'absolute', top: offsetY, left: offsetX, padding: 10 }}
>
  <input className={'blminput-' + ID} type="text" width={200} />
</div>*/

function mapStateToProps(state, ownProps) {
    return {
        user: state.user,
        book: state.book,
        books: state.books,
        loading: state.ajaxCallsInProgress > 0
    };
}

export default connect(mapStateToProps, {
    userUpdateProfile,
    userUpdatePW,
    userLogout,
    manualAjaxStart,
    manualAjaxEnd
})(BLMView);
