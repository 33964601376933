/* eslint-disable */
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import constants from './constants/constants';

import { NetworkListener } from './utilities/NetworkListener';
import { Provider } from 'react-redux';
import { Router, hashHistory } from 'react-router';
import routes from './routes';
import { Logger, CL_WARN, CL_ERROR } from './api/logger';
import config from './api/config';
import ReduxToastr from 'react-redux-toastr';
import {configureStore} from './store/configureStore';

import 'whatwg-fetch';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './App.scss';
import 'bootstrap/dist/js/bootstrap.min.js';
import './vendor/animate.min.css';
import './vendor/toastr.min.css';
import './vendor/reactReduxToaster.min.css';
import './vendor/fonts.css';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { readiumInitialized } from './actions/readiumActions';

// print version
console.log(
    `***** Version: ${config.Version} **** Environment: ${config.Environment}`
);

const NetworkListenerProvider = NetworkListener(Provider);

constants.themeProvider
.initTheme()

class IndexClass extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isRehydrated: false };
        const {store, persistor} = configureStore(() => {
            this.setState({ isRehydrated: true });
        });
        this.store = store;
        this.persistor = persistor;
        this.listenToReadiumInit();
    }
    render() {
        if (!this.state.isRehydrated) {
            return null;
        }
        return (
            // <Provider store={this.store}>
        <NetworkListenerProvider store={this.store}>
            <div>
                <Router history={hashHistory} routes={routes} />
                <ReduxToastr />
            </div>
        </NetworkListenerProvider>
        // </Provider>
        );
    }

    listenToReadiumInit() {
        if (window.readiumClass) {
            this.store.dispatch(readiumInitialized(window.readiumClass));
            return;
        }
        const handler = (ev) => {
            if (ev.data === 'readium-initialized') {
                window.removeEventListener('message', handler);
                this.store.dispatch(readiumInitialized(window.readiumClass));
            }
        };
        window.addEventListener('message', handler);
    }
}

ReactDOM.render(
    <IndexClass />,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        document.dispatchEvent(new Event('newVersionAvailable'));
        if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
    }
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

window.console = (function (origConsole) {
    if (!window.console || !origConsole) origConsole = {};
    var isDebug = false,
        logArray = {
            logs: [],
            errors: [],
            warns: [],
            infos: []
        };
    return {
        log: function () {
            logArray.logs.push(arguments);
            // Logger.log(CL_DEBUG, ...arguments);
            isDebug &&
                origConsole.log &&
                origConsole.log.apply(origConsole, arguments);
        },
        warn: function () {
            logArray.warns.push(arguments);
            Logger.log(CL_WARN, ...arguments);
            isDebug &&
                origConsole.warn &&
                origConsole.warn.apply(origConsole, arguments);
        },
        error: function () {
            logArray.errors.push(arguments);
            Logger.log(CL_ERROR, ...arguments);
            isDebug &&
                origConsole.error &&
                origConsole.error.apply(origConsole, arguments);
        },
        info: function (v) {
            logArray.infos.push(arguments);
            // Logger.log(CL_INFO, ...arguments);
            isDebug &&
                origConsole.info &&
                origConsole.info.apply(origConsole, arguments);
        },
        debug: function (bool) {
            isDebug = bool;
        },
        logArray: function () {
            return logArray;
        }
    };
})(window.console);
window.console.debug(true);
