import { READIUM_INITIALIZED } from '../actions/actionTypes';
import initialState from './initialState';

export default function readiumReducer(state = initialState.readium, action) {
    switch (action.type) {
        case READIUM_INITIALIZED:
            return { ...state, readiumClass: action.readiumClass };
        default:
            return state;
    }
}
