import * as types from './actionTypes';

export const startPointing = () => ({
    type: types.BOOK_TOOLBAR_POINTING
});

export const startNotes = (allowNotes) => ({
    type: types.BOOK_TOOLBAR_NOTES,
    allowNotes
});

export const startHighlighting = (highlighting, highlightColor) => ({
    type: types.BOOK_TOOLBAR_HIGHLIGHTING,
    highlighting,
    highlightColor
});

export const startErasing = (erasing) => ({
    type: types.BOOK_TOOLBAR_ERASING,
    erasing
});

export const startUnderlining = (underlining) => ({
    type: types.BOOK_TOOLBAR_UNDERLINING,
    underlining
});

export const startStriking = (striking) => ({
    type: types.BOOK_TOOLBAR_STRIKING,
    striking
});

export const startMoving = () => ({
    type: types.BOOK_TOOLBAR_MOVING
});
