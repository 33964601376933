import * as types from '../actions/actionTypes';
import initialState from './initialState';

export function bookbagFiltersReducer(
    state = initialState.bookbagFilters,
    action
) {
    switch (action.type) {
        case types.SAVE_ADVANCED_SEARCH_FILTERS:
            return { ...state, ...action.filters };
        // return Array.from(new Set(action.filters.tags));
        case types.CLEAR_ADVANCED_SEARCH_FILTERS:
            return initialState.bookbagFilters;
        case types.USER_LOGOUT_SUCCESS:
            return initialState.bookbagFilters;

        default:
            return state;
    }
}
