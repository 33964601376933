import * as React from 'react';
import { Route, IndexRoute } from 'react-router';
import App from './App';
import LoginForm from './components/auth/LoginForm';
import RegisterForm from './components/auth/RegisterForm';
import BookView from './components/bookview/BookView';
import BookBag from './components/bookbag/BookBag';
import BLMView from './components/blm/BLMView';
import Token from './components/auth/Token';
import { hashHistory } from 'react-router';

export const requireSignIn = (user, location) => {
    const redirect = `${location.pathname}${location.search}`;
    if (
        !user ||
        (user && !user.APIKey) ||
        (user && user.APIKey && user.APIKey.length === 0)
    ) {
        console.info('sign in required, redirecting user', redirect, user);
        hashHistory.push(`/?redirect=${redirect}`);
        return true;
    }
    return false;
};

export default (
    <Route path="/" component={App}>
        <IndexRoute component={LoginForm} />
        <Route path="login" component={LoginForm} />
        <Route path="register" component={RegisterForm} />
        <Route path="viewer(/:bookID)(/:blmID)" component={BookView} />
        {/* // All params for the BookBag are GET params... managed within the component */}
        <Route path="bag" component={BookBag} />
        <Route path="blm" component={BLMView} />
        {/* // All params for the Token component are GET params... managed within the component */}
        <Route path="tokenlogin" component={Token} />
    </Route>
);
