import * as React from 'react';

import { Button, Dropdown, FormControl, FormGroup } from 'react-bootstrap';

import { StyledButton } from '../../constants/styledComponents';
import constants from '../../constants/constants';
import icon_eraser from '../../images/icon_eraser.png';
import icon_pointer from '../../images/icon_pointer.png';
import icon_text from '../../images/t.png';
import { isIOS } from 'react-device-detect';

class ProjectToolbar extends React.Component {
    theme = constants.themeProvider.activeTheme;

    render() {
        const {
            showToolbar,
            blmButtonsClasses,
            currentFontSize,
            showAdminSaveButtons,
            showMarkersButtons,
            showStudentSaveButtons
        } = this.props.parentState;
        const showRichTextButtons = this.props.projectToolbar
            .showRichTextButtons;
        let pointerClassName, eraserClassName, textClassName, drawingClassName;
        this.props.projectToolbar.pointer
            ? (pointerClassName = 'hl-pointer active-button')
            : (pointerClassName = 'hl-pointer');
        this.props.projectToolbar.erasing
            ? (eraserClassName = 'eraser active-button')
            : (eraserClassName = 'eraser');
        this.props.projectToolbar.startText
            ? (textClassName = 'add-text active-button')
            : (textClassName = 'add-text');
        this.props.projectToolbar.drawingMode
            ? (drawingClassName = 'markers active-button')
            : (drawingClassName = 'markers');
        const projectToolbarStyle = {
            transform: `scale(${1 / this.props.projectScalePercent})`,
            transformOrigin: 'bottom left'
        };

        if (!showToolbar) {
            return null;
        }

        return (
            <div className={blmButtonsClasses} style={projectToolbarStyle}>
                {!showRichTextButtons && (
                    <StyledButton
                        className="icon-btn"
                        role="button"
                        variant="light"
                        onClick={this.props.exitBlm}
                        css={this.theme.primaryIconOnlyButtonStyle}
                    >
                        <i
                            className="close-blm fa fa-times"
                            aria-hidden="true"
                        ></i>
                    </StyledButton>
                )}
                <div className="dropup item">
                    <Button
                        className={`${pointerClassName} icon-btn`}
                        variant="light"
                        onClick={this.props.startPointer}
                        role="button"
                        title="Pointer Tool"
                    >
                        <img alt="" src={icon_pointer} />
                    </Button>
                    {showRichTextButtons && (
                        <div>
                            <FormGroup
                                className="icon-btn blm-select-outer"
                                controlId="formControlsSelect"
                            >
                                <FormControl
                                    className="blm-select"
                                    as="select"
                                    placeholder="select"
                                    value={currentFontSize}
                                    onChange={this.props.changeFontSize}
                                >
                                    <option value="16"> 8</option>
                                    <option value="20">10</option>
                                    <option value="24">12</option>
                                    <option value="28">14</option>
                                    <option value="32">16</option>
                                    <option value="36">18</option>
                                    <option value="40">20</option>
                                </FormControl>
                            </FormGroup>
                            <Button
                                className="icon-btn"
                                variant="light"
                                role="button"
                                onClick={this.props.toggleBold}
                            >
                                <i
                                    className="fa fa-bold"
                                    aria-hidden="true"
                                ></i>
                            </Button>
                            <Button
                                className="icon-btn"
                                variant="light"
                                role="button"
                                onClick={this.props.toggleItalic}
                            >
                                <i
                                    className="fa fa-italic"
                                    aria-hidden="true"
                                ></i>
                            </Button>
                            <Button
                                className="icon-btn"
                                variant="light"
                                role="button"
                                onClick={this.props.toggleUnderline}
                            >
                                <i
                                    className="fa fa-underline"
                                    aria-hidden="true"
                                ></i>
                            </Button>
                            <Button
                                className="icon-btn"
                                variant="light"
                                role="button"
                                onClick={this.props.toggleLinethrough}
                            >
                                <i
                                    className="fa fa-strikethrough"
                                    aria-hidden="true"
                                ></i>
                            </Button>
                        </div>
                    )}
                    {showMarkersButtons &&
                        this.props.isNotCompleteForStudent() &&
                        !showRichTextButtons && (
                            <div className="marker-dropdown-container">
                                <Dropdown id="marker-dropdown">
                                    <Dropdown.Toggle
                                        className={`${drawingClassName} item-child btn btn-light icon-btn hl-highlight`}
                                    >
                                        <img
                                            alt=""
                                            src={this.props.markerSelected}
                                        />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu toolbar-menu">
                                        <Dropdown.Item
                                            className="hl-orange"
                                            onClick={() =>
                                                this.props.startDrawingMode(
                                                    '#ff8f02'
                                                )
                                            }
                                        />
                                        <Dropdown.Item
                                            className="hl-black"
                                            onClick={() =>
                                                this.props.startDrawingMode(
                                                    '#000'
                                                )
                                            }
                                        />
                                        <Dropdown.Item
                                            className="hl-dark-blue"
                                            onClick={() =>
                                                this.props.startDrawingMode(
                                                    '#2A3DB9'
                                                )
                                            }
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        )}
                </div>
                {this.props.isNotCompleteForStudent() && !showRichTextButtons && (
                    <Button
                        className={`${textClassName} icon-btn`}
                        variant="light"
                        role="button"
                        onClick={this.props.startTextMode}
                    >
                        <img alt="" src={icon_text} />
                    </Button>
                )}
                {this.props.isNotCompleteForStudent() && !showRichTextButtons && (
                    <Button
                        className={`${eraserClassName} icon-btn`}
                        variant="light"
                        role="button"
                        onClick={this.props.startEraser}
                    >
                        <img alt="" src={icon_eraser} />
                    </Button>
                )}
                {showAdminSaveButtons && (
                    <Button
                        role="button"
                        variant="light"
                        onClick={this.props.saveBLMTemplate}
                    >
                        Save
                    </Button>
                )}
                {showStudentSaveButtons &&
                    this.props.isNotCompleteForStudent() &&
                    !showRichTextButtons && (
                        <Button
                            role="button"
                            variant="light"
                            onClick={this.props.showBlmSubmitModal}
                        >
                            Submit
                        </Button>
                    )}
                {showStudentSaveButtons && !showRichTextButtons && !isIOS && (
                    <Button
                        className="print-button"
                        variant="light"
                        role="button"
                        onClick={this.props.doPrint}
                    >
                        Print
                    </Button>
                )}
                {this.props.isNotCompleteForStudent() && !showRichTextButtons && (
                    <Button
                        className="reset"
                        variant="light"
                        role="button"
                        onClick={this.props.displayResetModal}
                    >
                        Reset
                    </Button>
                )}
            </div>
        );
    }
}

ProjectToolbar.propTypes = {};

export default ProjectToolbar;
