/*
 * This reducer contains all the visual state of the dashboard
 */

import * as types from '../actions/actionTypes';
import initialState from './initialState';

export function dashboard(state = initialState.dashboard, action) {
    switch (action.type) {
        case types.TOGGLE_USER_PROFILE_MODAL:
            return {
                ...state,
                showUserProfileModal: !state.showUserProfileModal
            };

        case types.TOGGLE_LOGGOUT_CONFIRM_MODAL:
            return {
                ...state,
                showLoggoutConfirmModal: !state.showLoggoutConfirmModal
            };
        case types.TOGGLE_CLASS_CODE_MODAL:
            let showClassCodeModal = !state.showClassCodeModal;
            if (action.show && typeof action.show === 'boolean'){
                showClassCodeModal = action.show;
            }
            return {
                ...state,
                showClassCodeModal
            };
        case types.USER_LOGOUT_SUCCESS:
            return initialState.dashboard;

        default:
            return state;
    }
}
