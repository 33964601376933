import { connect } from 'react-redux';
import { manualAjaxEnd } from '../../actions/ajaxStatusActions';
import {
    checkForUpdatedBookFiles,
    getBookItemsList,
    initialDashboardQuery,
    searchBookBagBooks
} from '../../actions/bookActions';
import { clearAdvancedSearchFilter } from '../../actions/bookbagFiltersActions';
import UserAPI from '../../api/userAPI';
import BookBagSearch from './BookBagSearch';

interface Iprops {
    query: typeof initialDashboardQuery;
    theme: any;
    isFiltersActive: boolean;
    handleInvalidSession: () => void;
}

const mapStateToProps = (state: any, ownProps: Iprops) => {
    const showSearchBar =
        UserAPI.isStudent(state.user.RoleID) ||
        UserAPI.isGeneric(state.user.RoleID);
    return {
        loading: state.ajaxCallsInProgress > 0,
        books: state.books,
        downloadedBooks: state.downloadedBooks,
        isGeneric: UserAPI.isGeneric(state.user.RoleID),
        isStudent: UserAPI.isStudent(state.user.RoleID),
        user: state.user,
        isOnline: state.offlineQueue.isOnline && navigator.onLine,
        bookbagFilters: state.bookbagFilters,
        showSearchBar
    };
};

export const BookBagSearchContainer = connect(mapStateToProps, {
    getBookItemsList,
    checkForUpdatedBookFiles,
    clearAdvancedSearchFilter,
    searchBookBagBooks: searchBookBagBooks,
    manualAjaxEnd: manualAjaxEnd
})(BookBagSearch);
