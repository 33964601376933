/*
*   1: import this util with something like:  import {validateField, validateForm} from '../common/UtilValidation.ts';
    2: add formValidations and formValid to state
    3: call validateAllFields inside didMount
    4: call validateField inside handleChange like this:
      const fv = validateField(name, value, true, this.state.formValidations);
      const vf = validateForm(this.state.formValidations);
      this.setState({ [name]: value, formValidations: fv, formValid: vf });
    5: update the react-bootstrap form elements
    6: inside validateAllFields - add all the fields you want to validate
    7: inside handleSubmit check state.formValid and if it is not valid then prevent submitting the form
      and display some sort of error encouraging the user to check their inputs
    8: inside handleSubmit you also need to trigger re-validating the fields like this:
      this.validateAllFields(true);
      this.forceUpdate(); // needed because if the user clicks submit whithout touching some fields, then the inlide validation does not show as expected

*
*/
import { forEach, find } from 'lodash';
// export interface FormField{
//   validationState: string;
//   validators: FormValidators[];
// }

// export interface FormValidations{
//   name: FormField;
// }

// export interface FormValidators{
//   pattern?: any;
//   require?: boolean;
//   message: string;
// }

/*
 * FORM VALIDATION
 */
const validate = (value: string, validator: any, matchValue?: string) => {
    let valid = true;
    if (validator.pattern && value.length > 0) {
        let newValue = value.match(validator.pattern);
        valid = newValue ? true : false;
    }
    if (validator.required) {
        valid = !!value.length || false;
    }
    if (validator.mustMatch) {
        valid = matchValue === value ? true : false;
    }
    return valid;
};
export const validateField = (
    name: string,
    value: string,
    showErrors: boolean,
    formValidations: any,
    matchValue?: string
) => {
    // loop over each field we need to validate
    forEach(formValidations, (field, key) => {
        if (key === name) {
            // let newFormValidations = Object.assign({}, this.state.formValidations);
            let errorMessage = '';

            // loop over each validation for this field, and set error to the error message if something is invalid
            field.validators.forEach((validator) => {
                if (!validate(value, validator, matchValue)) {
                    errorMessage = validator.message;
                }
            });
            // after we have checked all the validators, is there an error message for this field?
            if (!errorMessage) {
                // no error message
                formValidations[name].validationState = 'success';
                formValidations[name].validationMessage = '';
                formValidations[name].valid = true;
                if (!showErrors) {
                    formValidations[name].validationState = null;
                    formValidations[name].validationMessage = '';
                }
            } else {
                // error
                // only show the errors if showErrors
                if (showErrors) {
                    formValidations[name].validationState = 'error';
                    formValidations[name].validationMessage = errorMessage;
                }

                formValidations[name].valid = false;
            }
            // return formValidations;
            // this.setState({formValidations: formValidations}, this.validateForm);
        }
    });
    return formValidations;
};

export const validateForm = (formValidations: any) => {
    return !find(formValidations, ['valid', false]);
    // this.setState({formValid});
};

export default validateField;
