import * as types from '../actionTypes';

export function turnOnEraser() {
    let projectToolbar = {
        erasing: true,
        pointer: false,
        startText: false,
        drawingMode: false,
        showRichTextButtons: false,
        drawingColor: '#ff8f02'
    };
    return { type: types.TOOLBAR_ERASER_ON, projectToolbar };
}

export function turnOnTextMode() {
    let projectToolbar = {
        erasing: false,
        pointer: false,
        startText: true,
        drawingMode: false,
        showRichTextButtons: false,
        drawingColor: '#ff8f02'
    };
    return { type: types.TOOLBAR_ADD_TEXT_ON, projectToolbar };
}

export function showRichTextButtons() {
    let projectToolbar = {
        erasing: false,
        pointer: false,
        startText: false,
        drawingMode: false,
        showRichTextButtons: true,
        drawingColor: '#ff8f02'
    };
    return { type: types.SHOW_RICH_TEXT_BUTTONS, projectToolbar };
}

export function turnOnPointer() {
    let projectToolbar = {
        erasing: false,
        pointer: true,
        startText: false,
        drawingMode: false,
        showRichTextButtons: false,
        drawingColor: '#ff8f02'
    };
    return { type: types.TOOLBAR_POINTER_ON, projectToolbar };
}

export function turnOnDrawingMode(color) {
    let projectToolbar = {
        erasing: false,
        pointer: false,
        startText: false,
        drawingMode: true,
        showRichTextButtons: false,
        drawingColor: `${color}`
    };
    return { type: types.TOOLBAR_DRAWING_MODE_ON, projectToolbar };
}
