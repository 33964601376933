import config from './config';

export default class BlmAPI {
    static getAssignedBLMs(bookID, user) {
        const URL = `${config.API.Main}/blacklinemaster/getassignedblms?bookid=${bookID}`;
        var request = new Request(URL, {
            method: 'GET',
            headers: this.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json().then((blms) => {
                return blms;
            });
        });
    }

    static studentAssignedBLMs(user) {
        const URL = `${config.API.Main}/blacklinemaster/getstudentassignedblms`;
        var request = new Request(URL, {
            method: 'GET',
            headers: this.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json().then((blms) => {
                return blms;
            });
        });
    }

    static getBLMByID(blmID, user) {
        // blacklinemaster/getblm?blmiID=XXX
        const URL = `${config.API.Main}/blacklinemaster/getblm?blmID=${blmID}`;
        var request = new Request(URL, {
            method: 'GET',
            headers: this.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static getBLMProperties(blm, user) {
        const remoteURL = `${config.API.Storage}${blm.EBookPath}properties.json${user}`;
        var request = new Request(remoteURL, { method: 'GET' });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static getBLMStatus(blmID, projectAssignmentID, user) {
        const URL = `${config.API.Main}/blacklinemaster/getstatus?blmID=${blmID}&projectAssignmentID=${projectAssignmentID}`;
        var request = new Request(URL, {
            method: 'GET',
            headers: this.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static getBlmPageRemote(blm, index, token) {
        if (!token) {
            throw new Error('missing token');
        }
        // Just took this out from root url: ${book.ISBN}
        //https://dibsbookstest.blob.core.windows.net/books/BrainHive/Batch18/1/?sv=2016-05-31&sr=c&sig=FdBI2tdzVIdUMA4CQ1a7y2y6F6dS7a5v9QegJlqnmqM%3D&se=2017-09-16T20%3A21%3A19Z&sp=rl
        const root = `${config.API.Storage}${blm.EBookPath}`;
        // const htmURL = `${root}/${index}.html${token}`;
        const htmURL = `${root}${index}.html${token}`;
        const svgURL = `${root}${index}/${index}.svg${token}`;

        // fetch the html for the page
        const p1 = fetch(htmURL).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.text();
        });
        // fetch the svg data for the page
        const p2 = fetch(svgURL)
            .then((response) => {
                if (response.status !== 200) {
                    throw response;
                }
                return response.text();
            })
            // catch any errors with svg fetch so we can
            // inspect and not autofail the whole request.
            .catch((err) => {
                if (err.status === 404) {
                    // then this is a benign error - the svg is simply not there as expected
                    return err.status;
                } else {
                    throw err;
                }
            });
        return Promise.all([p1, p2]).then((values) => {
            // return values;
            let pageData = values[0];
            let svgData = values[1];
            if (svgData === 404) {
                //not found
                svgData = '';
            } else {
                // replace all relative image urls with full url
                svgData = svgData.replace(
                    /((\w*)\/[0-9]*.(png|jpg))+/g,
                    `${root}${index}/$1${token}`
                );
                // remove extra crap we do not need for an embedded svg
                svgData = svgData.replace(
                    '<?xml version="1.0" encoding="UTF-8" standalone="no"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">',
                    ''
                );
            }
            // now replace the entire svg <object> in the html with the svg element
            pageData = pageData.replace(/(<object[^<]*<\/object>)+/, svgData);
            // replace fonts path
            pageData = pageData.replace(
                /(fonts\/[a-zA-Z0-9\-_]*.\w*)/g,
                `${root}$1${token}`
            );
            return pageData;
        });
    }

    static updateStatus(blm, user) {
        const URL = `${config.API.Main}/blacklinemaster/updatestatus`;
        const body = JSON.stringify({
            ...blm,
            Content: JSON.stringify(blm.Content)
        });

        var request = new Request(URL, {
            method: 'POST',
            headers: this.getHeaders(user),
            body: body
        });
        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static saveBLMTemplate(blmItem, user) {
        const URL = `${config.API.Main}/viewer/saveblmtemplate`;
        const body = JSON.stringify({
            ...blmItem,
            Content: JSON.stringify(blmItem.Content)
        });

        var request = new Request(URL, {
            method: 'POST',
            headers: this.getHeaders(user),
            body: body
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static saveBLMItem(blmItem, user) {
        const URL = `${config.API.Main}/viewer/saveblmitem`;
        const body = JSON.stringify({
            ...blmItem,
            Content: JSON.stringify(blmItem.Content)
        });

        var request = new Request(URL, {
            method: 'POST',
            headers: this.getHeaders(user),
            body: body
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static getBLMItems(blmID, paID, user) {
        const URL = `${config.API.Main}/viewer/getblmitems?blmID=${blmID}&projectAssignmentID=${paID}`;

        var request = new Request(URL, {
            method: 'GET',
            headers: this.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static getTemplateItems(blmID, user) {
        const URL = `${config.API.Main}/viewer/gettemplateitems?blmID=${blmID}`;

        var request = new Request(URL, {
            method: 'GET',
            headers: this.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static getStudentsBLMItems(blmID, paID, studentID, user) {
        const URL = `${config.API.Main}/viewer/getstudentblmitems?blmID=${blmID}&studentID=${studentID}&projectAssignmentID=${paID}`;
        var request = new Request(URL, {
            method: 'GET',
            headers: this.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static teacherMakesBLMComment(data, user) {
        const URL = `${config.API.Main}/blacklinemaster/saveteachercomment`;
        const body = JSON.stringify({
            ...data,
            Content: JSON.stringify(data.Content)
        });

        var request = new Request(URL, {
            method: 'POST',
            headers: this.getHeaders(user),
            body: body
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static getTeachersComment(paID, TeacherID, user) {
        const URL = `${config.API.Main}/blacklinemaster/getstudentteachercomment?ProjectAssignmentID=${paID}&teacherId=${TeacherID}`;
        var request = new Request(URL, {
            method: 'GET',
            headers: this.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    /*
    Convenience method to construct headers using a user object to obtain
    their apiKey
  */

    static getHeaders(user = false) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (!!user) {
            headers.append('apiKey', user.APIKey || '');
        }
        return headers;
    }
}
