import config from './config';
import { viewerModes } from '../constants/viewerModes';

export default class UserAPI {
    static login(loginID, password, schoolID) {
        var URL = `${config.API.Main}/student/login`;
        var data = { loginID, password, schoolID };
        var body = JSON.stringify(data);
        var request = new Request(URL, {
            method: 'POST',
            headers: this.getHeaders(),
            body: body,
            credentials: 'include'
        });

        return fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    throw response;
                }
                return response.json();
            })
            .then((jsonData) => {
                return jsonData;
            });
    }

    static tokenLogin(token) {
        var URL = `${config.API.Main}/account/tokenlogin?token=${token}`;
        var request = new Request(URL, {
            method: 'GET',
            headers: this.getHeaders(),
            credentials: 'include'
        });

        return fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    throw response;
                }
                return response.json();
            })
            .then((jsonData) => {
                return jsonData;
            });
    }

    static googleTokenLogin(authorizationCode, schoolID) {
        var URL = `${config.API.Main}/google/tokenlogin`;
        var body = JSON.stringify({
            googleAuthorizationCode: authorizationCode,
            schoolID
        });
        var request = new Request(URL, {
            method: 'POST',
            headers: this.getHeaders(),
            body: body
        });

        return fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    throw response;
                }
                return response.json();
            })
            .then((data) => {
                if (!data) {
                    return Promise.reject({
                        message:
                            'Unknown server error. Please try again or contact support.'
                    });
                } else {
                    return data;
                }
            });
    }

    static updateBookStatus(
        bookID,
        currentPage,
        totalPages,
        pageView,
        bookTimeout,
        user
    ) {
        var URL = `${config.API.Main}/student/updatebookstatus `;
        // var body = JSON.stringify({ bookID, currentPage });
        bookTimeout = bookTimeout ? true : '';
        let body = `bookID=${bookID}&currentPage=${currentPage}&totalPages=${totalPages}&pageView=${pageView}&bookTimeout=${bookTimeout}`;
        var request = new Request(URL, {
            method: 'POST',
            headers: this.getHeadersUrlEncode(user),
            body: body
        });

        return fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    throw response;
                }
                return response.json();
            })
            .then((jsonData) => {
                return jsonData;
            });
    }

    // Check if a user is valid and get back current user object
    static checkSession(user) {
        var URL = `${config.API.Main}/account/checksession`;
        var request = new Request(URL, {
            method: 'POST',
            headers: this.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static updateProfile(user, firstName, lastName, loginID) {
        var URL = `${config.API.Main}/student/updateprofile`;
        let body = `first=${firstName}&last=${lastName}&loginID=${loginID}`;
        var request = new Request(URL, {
            method: 'POST',
            headers: this.getHeadersUrlEncode(user),
            body: body
        });

        return fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    throw response;
                }
                return response.json();
            })
            .then((jsonData) => {
                return jsonData;
            });
    }

    static addClassCode(user, code) {
        var URL = `${config.API.Main}/student/AddClassGroupCode?code=${code}`;
        var request = new Request(URL, {
            method: 'POST',
            headers: this.getHeadersUrlEncode(user),
        });

        return fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    throw response;
                }
                return response.text();
            })
            .then((jsonData) => {
                return jsonData;
            });
    }

    static updateProfilePW(user, password) {
        var URL = `${config.API.Main}/student/updatepassword`;
        let body = `password=${password}`;
        var request = new Request(URL, {
            method: 'POST',
            headers: this.getHeadersUrlEncode(user),
            body: body
        });

        return fetch(request)
            .then((response) => {
                if (response.status !== 200) {
                    throw response;
                }
                return response.json();
            })
            .then((jsonData) => {
                return jsonData;
            });
    }

    /*
    Get schools
  */
    static getSchools(search) {
        const URL = `${config.API.Main}/school/viewersearch?search=${search}`;
        var request = new Request(URL, { method: 'GET' });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    static getAdvancedSearch(search, user) {
        const URL = `${config.API.Main}/tag/gettagsinaschool?search=${search}`;
        var request = new Request(URL, {
            method: 'GET',
            headers: this.getHeaders(user)
        });

        return fetch(request).then((response) => {
            if (response.status !== 200) {
                throw response;
            }
            return response.json();
        });
    }

    /*
  Convenience method to construct headers using a user object to obtain
  their apiKey
*/
    static getHeaders(user = false) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        if (!!user) {
            headers.append('apiKey', user.APIKey || '');
        }
        return headers;
    }

    static getHeadersUrlEncode(user = false) {
        var headers = new Headers();
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        if (!!user) {
            headers.append('apiKey', user.APIKey || '');
        }
        return headers;
    }

    // Temporary fix for check session roleID being undefined
    // Other Solutions:
    // 1. Check if user is undefined in top component except where there is needed authorization
    // 2. Add a else statement that returns either null or some other property
    // Remember, good things come to those who are patient but better things to those who are crafty. :D
    static canAccessBook(roleID, viewerMode) {
        if (
            roleID !== undefined &&
            (roleID.toUpperCase() ===
                config.UserRoleIDs.Generic.toUpperCase() ||
                viewerMode === viewerModes.MODE_TEACHER_VIEW_STUDENT_BOOK ||
                viewerMode === viewerModes.MODE_TEACHER_GROUP_NOTES ||
                viewerMode === viewerModes.MODE_TEACHER_CLASS_NOTES ||
                roleID.toUpperCase() ===
                    config.UserRoleIDs.Student.toUpperCase() ||
                viewerMode === viewerModes.MODE_TEACHER_STUDENT_BLM)
        ) {
            return true;
        } else {
            return false;
        }
    }
    static isStudent(roleID) {
        if (roleID !== undefined) {
            return (
                roleID.toUpperCase() ===
                config.UserRoleIDs.Student.toUpperCase()
            );
        } else {
            return false;
        }
    }
    static isTeacher(roleID) {
        if (roleID !== undefined) {
            return (
                roleID.toUpperCase() ===
                config.UserRoleIDs.Teacher.toUpperCase()
            );
        } else {
            return false;
        }
    }
    static isSuperAdmin(roleID) {
        if (roleID !== undefined) {
            return (
                roleID.toUpperCase() ===
                config.UserRoleIDs.SuperAdmin.toUpperCase()
            );
        } else {
            return false;
        }
    }
    static isSchoolAdmin(roleID) {
        if (roleID !== undefined) {
            return (
                roleID.toUpperCase() ===
                config.UserRoleIDs.SchoolAdmin.toUpperCase()
            );
        } else {
            return false;
        }
    }
    static isSalesRep(roleID) {
        if (roleID !== undefined) {
            return (
                roleID.toUpperCase() ===
                config.UserRoleIDs.SalesRep.toUpperCase()
            );
        } else {
            return false;
        }
    }
    static isGeneric(roleID) {
        if (roleID !== undefined) {
            return (
                roleID.toUpperCase() ===
                config.UserRoleIDs.Generic.toUpperCase()
            );
        } else {
            return false;
        }
    }
    static isDemo(roleID) {
        if (roleID !== undefined) {
            return (
                roleID.toUpperCase() === config.UserRoleIDs.Demo.toUpperCase()
            );
        } else {
            return false;
        }
    }
}
