export const viewerModes = {
    MODE_GENERIC: 'generic',
    MODE_TEACHER_VIEW_STUDENT_BOOK: 'teacherstudentnotes',
    MODE_TEACHER_CLASS_NOTES: 'teacherclassnotes',
    MODE_TEACHER_GROUP_NOTES: 'teachergroupnotes',
    MODE_TEACHER_STUDENT_BLM: 'teacherstudentblm',
    MODE_TEACHER_BLM: 'teacherblm',
    MODE_ADMIN_CREATE_TEMPLATE: 'create',
    MODE_RESOURCE: 'resource',
    MODE_STUDENT: 'student'
};
