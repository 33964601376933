import * as React from 'react';
import { FormGroup, FormControl, Row, Col } from 'react-bootstrap';

class UserProfileForm extends React.Component {
    render() {
        return (
            <Row>
                <Col>
                    {this.props.forms.map((form) => {
                        return (
                            <FormGroup key={form.id}>
                                <FormControl
                                    id={form.id}
                                    name={form.name}
                                    placeholder={form.placeholder}
                                    value={form.value}
                                    type={form.type}
                                    onChange={this.props.handleChange}
                                />
                            </FormGroup>
                        );
                    })}
                    <p className="password-text pull-left">
                        <span className="first-row">
                            Need to update your password?
                        </span>
                        <br />
                        <span>
                            Enter the new one twice above, otherwise leave it
                            blank.
                        </span>
                    </p>
                </Col>
            </Row>
        );
    }
}

export default UserProfileForm;
