import rangy from 'rangy/lib/rangy-core';
import 'rangy/lib/rangy-textrange';
import 'rangy/lib/rangy-serializer';
import 'rangy/lib/rangy-selectionsaverestore';
import 'rangy/lib/rangy-highlighter';
import 'rangy/lib/rangy-classapplier';

const testForSimilarMarkup = (element, highlightColor) => {
    if (
        element.className.includes(highlightColor) &&
        element.textContent !== ' '
    ) {
        // alert('found similar')
        return true;
    } else {
        return false;
    }
};

// search through all the possible matches, then merge them
const findSimilarMarkups = (target, whichSide, pid, highlightColor) => {
    let range = rangy.createRange();
    let currentRange = rangy.createRange();
    let willMergeMarkups = false;
    currentRange.selectNodeContents(target);
    range.selectNode(target); // start with the initial selection of the word
    findPreviousMarkups(target); // increase the range before this markup?
    findNextMarkups(target); // increase the selection range after this markup?
    if (willMergeMarkups) {
        range.select();
    }

    function findPreviousMarkups(element) {
        let didFindElement = false; // keep track of if we found one
        let foundElement;

        // find elements in non-audio books
        try {
            if (
                testForSimilarMarkup(
                    element.previousElementSibling,
                    highlightColor
                )
            ) {
                didFindElement = true;
                foundElement = element.previousElementSibling;
            }
        } catch (error) {}
        // find elements that are on different lines
        try {
            if (
                !foundElement &&
                element.parentNode.children.length === 1 &&
                element.parentNode.className.includes('t') &&
                testForSimilarMarkup(
                    element.parentNode.previousElementSibling.children[0],
                    highlightColor
                )
            ) {
                didFindElement = true;
                foundElement =
                    element.parentNode.previousElementSibling.children[0];
            }
        } catch (error) {}
        // find elements in audio books - they are buried in an additional span occassionaly
        try {
            if (
                !foundElement &&
                element.parentNode.children.length === 1 &&
                element.parentNode.className.includes('t') &&
                testForSimilarMarkup(
                    element.parentNode.previousElementSibling.children[0]
                        .children[0],
                    highlightColor
                )
            ) {
                didFindElement = true;
                foundElement =
                    element.parentNode.previousElementSibling.children[0]
                        .children[0];
            }
        } catch (error) {}

        // if we found something, then take that something and see if there is another similar markup before that
        if (didFindElement) {
            willMergeMarkups = true;
            range.setStart(foundElement);

            findPreviousMarkups(foundElement);
        }
    }

    function findNextMarkups(element) {
        let didFindElement = false; // keep track of if we found one
        let foundElement;
        try {
            if (
                testForSimilarMarkup(element.nextElementSibling, highlightColor)
            ) {
                didFindElement = true;
                foundElement = element.nextElementSibling;
            }
        } catch (error) {}

        try {
            if (
                !foundElement &&
                element.parentNode.children.length === 1 &&
                element.parentNode.className.includes('t') &&
                testForSimilarMarkup(
                    element.parentNode.nextElementSibling.children[0],
                    highlightColor
                )
            ) {
                didFindElement = true;
                foundElement =
                    element.parentNode.nextElementSibling.children[0];
            }
        } catch (error) {}
        try {
            if (
                !foundElement &&
                element.parentNode.children.length === 1 &&
                element.parentNode.className.includes('t') &&
                testForSimilarMarkup(
                    element.parentNode.nextElementSibling.children[0]
                        .children[0],
                    highlightColor
                )
            ) {
                didFindElement = true;
                foundElement =
                    element.parentNode.nextElementSibling.children[0]
                        .children[0];
            }
        } catch (error) {}
        // if we found something, then take that something and see if there is another similar markup AFTER that
        if (didFindElement) {
            willMergeMarkups = true;
            range.setEnd(
                foundElement.firstChild,
                foundElement.firstChild.length
            );

            findNextMarkups(foundElement);
        }
    }
};

export default findSimilarMarkups;
