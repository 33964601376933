import * as types from '../actions/actionTypes';
import initialState from './initialState';

export function projectToolbar(state = initialState.projectToolbar, action) {
    switch (action.type) {
        case types.TOOLBAR_ERASER_ON:
            return Object.assign({}, state, action.projectToolbar);
        case types.TOOLBAR_POINTER_ON:
            return Object.assign({}, state, action.projectToolbar);
        case types.TOOLBAR_DRAWING_MODE_ON:
            return Object.assign({}, state, action.projectToolbar);
        case types.TOOLBAR_ADD_TEXT_ON:
            return Object.assign({}, state, action.projectToolbar);
        case types.SHOW_RICH_TEXT_BUTTONS:
            return Object.assign({}, state, action.projectToolbar);
        default:
            return state;
    }
}
