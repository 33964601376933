import config from './config';
import { loadState } from '../store/localStorage';

export const CL_DEBUG = 1;
export const CL_VERBOSE = 2;
export const CL_INFO = 3;
export const CL_WARN = 4;
export const CL_ERROR = 5;
export const CL_CRITICAL = 6;

export class Logger {
    static log(severity, ...args) {
        return loadState('dibs').then((state) => {
            let text = { ...args };
            if (severity === CL_ERROR || severity === CL_CRITICAL) {
                text = { ...args, reduxStore: state };
            }
            var obj = {
                privateKey: config.Coralogix.key,
                applicationName: 'DiBS.' + config.Environment,
                subsystemName: config.Coralogix.subsystemName,
                computerName:
                    state && state.user
                        ? `${state.user.First} ${state.user.Last}`
                        : 'not logged in',
                logEntries: [
                    {
                        timestamp: new Date().getTime(),
                        severity: severity,
                        text: text,
                        category:
                            state && state.user
                                ? state.user.APIKey
                                : 'no api key'
                    }
                ]
            };

            return this.sendToCoralogix(obj);
        });
    }

    static sendToCoralogix(obj) {
        if (process.env.NODE_ENV !== 'production') {
            return true;
        }
        return fetch('https://api.coralogix.com/api/v1/logs', {
            method: 'POST',
            headers: new Headers({ 'content-type': 'application/json' }),
            body: JSON.stringify(obj)
        })
            .then(function (response) {
                return response.text();
            })
            .then(function (data) {
                return data;
            })
            .catch(function (ex) {
                // if logging fails... what can ya do?
                throw ex;
            });
    }
}
