/*
 * This page is not currently being used in DiBS
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userRegister } from '../../actions/userActions';

import { hashHistory } from 'react-router';
import { ToastContainer, ToastMessage } from 'react-toastr';
import { Container, Button } from 'react-bootstrap';
import constants from '../../constants/constants';

const ToastMessageFactory = React.createFactory(ToastMessage.animation);

class RegisterForm extends Component {
    reffirst;
    reflast;
    refemail;
    refpassword;
    refpassword2;
    toastCont;

    constructor(props) {
        super(props);
        this.state = {
            first: 'Billy',
            last: 'Bookreader',
            loginID: 'tester',
            password: '12345',
            password2: '12345'
        };

        // var apikey = localStorage.getItem("GF-APIKey");
        // if (apikey) {
        //   window.location = '/admin/';
        // }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goBack = this.goBack.bind(this);
        this.theme = constants.themeProvider.activeTheme;
    }

    goBack() {
        hashHistory.push('/');
    }

    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        if (e !== '') {
            e.preventDefault();
        }

        if (this.state.loginID === '') {
            this.toastCont.error(
                `Please enter a valid email address.`,
                `Form Error`,
                {
                    closeButton: true,
                    showAnimation: 'animated fadeInUp'
                }
            );
            this.refemail.focus();
            return;
        }

        this.props
            .userRegister(this.state)
            .then((user) => {
                // TODO: route somewhere for student and admin
            })
            .catch((error) => {
                console.error(error);
                if (error.status === 409) {
                    // email already exists
                    this.toastCont.error(
                        `That email address is already registered in our system.`,
                        `Registration Error`,
                        {
                            closeButton: true,
                            showAnimation: 'animated fadeInUp'
                        }
                    );
                }
            });
    }

    render() {
        return (
            <Container className="registerPage content">
                <h1 className="text-center">Welcome to GrammarFlip!</h1>
                <div className="row">
                    <div className="col-md-6">
                        <form
                            id="registerForm"
                            onSubmit={(e) => this.handleSubmit(e)}
                        >
                            <div className="form-group">
                                <label htmlFor="first">Honorific</label>
                                <select
                                    className="form-control form-control-lg"
                                    id="gender"
                                    ref={(input) => {
                                        this.refgender = input;
                                    }}
                                    name="gender"
                                    value={this.state.gender}
                                    onChange={this.handleChange}
                                >
                                    <option value="O">None</option>
                                    <option value="M">Mr.</option>
                                    <option value="F">Mrs.</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="first">First Name</label>
                                <input
                                    className="form-control form-control-lg"
                                    type="text"
                                    id="first"
                                    ref={(input) => {
                                        this.reffirst = input;
                                    }}
                                    name="first"
                                    placeholder="First Name"
                                    value={this.state.first}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="last">Last Name</label>
                                <input
                                    className="form-control form-control-lg"
                                    type="text"
                                    id="last"
                                    ref={(input) => {
                                        this.reflast = input;
                                    }}
                                    name="last"
                                    placeholder="Last Name"
                                    value={this.state.last}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    className="form-control form-control-lg"
                                    type="email"
                                    id="email"
                                    ref={(input) => {
                                        this.refemail = input;
                                    }}
                                    name="email"
                                    placeholder="Email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password">Password</label>
                                <input
                                    className="form-control form-control-lg"
                                    type="password"
                                    id="password"
                                    ref={(input) => {
                                        this.refpassword = input;
                                    }}
                                    name="password"
                                    placeholder="Password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="password2">
                                    Confirm Password
                                </label>
                                <input
                                    className="form-control form-control-lg"
                                    type="password"
                                    id="password2"
                                    ref={(input) => {
                                        this.refpassword2 = input;
                                    }}
                                    name="password2"
                                    placeholder="Confirm Password"
                                    value={this.state.password2}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div className="form-group">
                                I am a
                                <select
                                    className="form-control form-control-lg"
                                    id="role"
                                    ref={(input) => {
                                        this.refrole = input;
                                    }}
                                    name="role"
                                    value={this.state.role}
                                    onChange={this.handleChange}
                                >
                                    <option value="">--Please select--</option>
                                    <option value="Elementary School Teacher">
                                        Elementary School Teacher
                                    </option>
                                    <option value="Middle School Teacher">
                                        Middle School Teacher
                                    </option>
                                    <option value="High School Teacher">
                                        High School Teacher
                                    </option>
                                    <option value="University/College Instructor">
                                        University/College Instructor
                                    </option>
                                    <option value="Home School Parent">
                                        Home School Parent
                                    </option>
                                    <option value="Tutor">Tutor</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <p className="register-terms">
                                <small>
                                    By clicking <b>Be a Teacher</b> below you
                                    agree to the GrammarFlip{' '}
                                    <a
                                        href="https://grammarflip.com/privacy-policy/"
                                        target="_blank"
                                    >
                                        Privacy Policy and Terms of Use
                                    </a>
                                    .
                                </small>
                            </p>
                            <div className="row rowPadding">
                                <div className="col-md-12">
                                    <div className="left">
                                        <Button
                                            disabled={this.props.loading}
                                            id="btnRegister"
                                            variant="info"
                                            type="submit"
                                        >
                                            BE A TEACHER
                                        </Button>
                                        <Button
                                            className="backLink"
                                            onClick={this.goBack}
                                        >
                                            back to login
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-6">
                        <div className="fusion-column-wrapper">
                            <h2 data-fontsize="28" data-lineheight="41">
                                Start your FREE, 30-day trial today!
                            </h2>

                            <h4 data-fontsize="20" data-lineheight="30">
                                Experience GrammarFlip's 60-Lesson Subscription.
                                Each lesson contains the following features:
                            </h4>

                            <ul>
                                <li>
                                    Instructional videos that allow for
                                    self-paced learning
                                </li>
                                <li>
                                    Slideshow presentations that provide quick
                                    review of material
                                </li>
                                <li>
                                    Practice exercises with instant feedback
                                </li>
                                <li>
                                    Writing activities to apply the concepts
                                    learned
                                </li>
                            </ul>

                            <h4 data-fontsize="20" data-lineheight="30">
                                A Teacher Trial Account comes with the
                                following:
                            </h4>
                            <ul>
                                <li>150 FREE student accounts</li>
                                <li>As many as 6 different class sections</li>
                                <li>
                                    Easy student account management and setup
                                </li>
                                <li>
                                    Student progress reports in quick-view
                                    format
                                </li>
                                <li>
                                    Additional content integrations with
                                    Formative and Kahoot
                                </li>
                            </ul>

                            <h3
                                className="text-center"
                                data-fontsize="24"
                                data-lineheight="38"
                            >
                                Get started today! It’s free!
                            </h3>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    toastMessageFactory={ToastMessageFactory}
                    ref={(toast) => {
                        this.toastCont = toast;
                    }}
                    className="toast-bottom-center"
                />
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        loading: state.ajaxCallsInProgress > 0
    };
};

export default connect(mapStateToProps, { userRegister })(RegisterForm);
