import * as types from './actionTypes';
import BlmAPI from '../api/blmAPI';
import { beginAjaxCall } from './ajaxStatusActions';
import { map, forEach } from 'lodash';
import { debouncedCheckSession } from './userActions';

export function getAssignedBLMs(bookID, user) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return BlmAPI.getAssignedBLMs(bookID, user)
            .then((blms) => {
                blms = map(blms[0], (blm) => {
                    let tempObj = Object.assign(
                        {},
                        blm.ProjectItem.BlackLineMaster
                    );
                    tempObj.ProjectItemID = blm.ProjectItemID;
                    tempObj.projectAssignmentID = blm.ID;
                    // grab the AccountID - this is the teacher's ID.  sometimes it is assinged to a class and sometimes a group
                    if (blm.Class && blm.Class.AccountID) {
                        tempObj.TeacherID = blm.Class.AccountID;
                    }
                    if (blm.Group && blm.Group.AccountID) {
                        tempObj.TeacherID = blm.Group.AccountID;
                    }
                    return tempObj;
                });
                dispatch({ type: types.LOAD_BLMS_SUCCESS, blms });

                return blms;
            })
            .catch((error) => {
                dispatch({ type: types.LOAD_BLMS_FAILED });
                throw error;
            });
    };
}
// We might need this call in the future
// export function studentAssignedBLMs(user){
//   return function (dispatch, getState) {
//     return BlmAPI.studentAssignedBLMs(user)
//       .then(blms => {
//         dispatch({ type: types.GET_COMPLETED_BLMS_SUCCESS, completedBLMs: blms[1] });
//       })
//       .catch(err => {
//         dispatch({ type: types.GET_COMPLETED_BLMS_FAILED})
//         console.log(err);
//       })
//   }
// }

export function emptyBLMs() {
    return { type: types.LOAD_BLMS_SUCCESS, blms: [] };
}

export function emptyBLMData(blm) {
    delete blm.blmData;
    return {
        type: types.REMOVE_BLM_DATA_SUCCESS,
        blm: blm
    };
}

export function teacherMakesBLMComment(data, token) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return BlmAPI.teacherMakesBLMComment(data, token)
            .then((comment) => {
                let teachersComment = comment.Text;
                dispatch({
                    type: types.SAVE_TEACHERS_COMMENT_SUCCESS,
                    teachersComment
                });
                return teachersComment;
            })
            .catch((error) => {
                dispatch({ type: types.SAVE_TEACHERS_COMMENT_FAILED, error });
                throw error;
            });
    };
}

export function getBLMProperties(blmObj, token) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return BlmAPI.getBLMProperties(blmObj, token)
            .then((blmProperties) => {
                const blm = Object.assign({}, blmObj, blmProperties);

                // if (!blm.fabricjs) { blm.fabricjs = {}; }  // TODO store the fabricjs item here???
                dispatch({ type: types.FETCH_BLM_SUCCESS, blm });
                return blm;
            })
            .catch((error) => {
                if (error.status === 403) {
                    debouncedCheckSession(getState().user, dispatch);
                }
                dispatch({ type: types.FETCH_BLM_FAILED, error });
                throw error;
            });
    };
}

export function getTeachersComment(paID, TeacherID, user) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return BlmAPI.getTeachersComment(paID, TeacherID, user)
            .then((comment) => {
                let teachersComment;
                if (comment === null) {
                    teachersComment = '';
                } else {
                    teachersComment = comment.Text;
                }
                dispatch({
                    type: types.GET_TEACHERS_BLM_COMMENT_SUCCESS,
                    teachersComment
                });
            })
            .catch((error) => {
                dispatch({
                    type: types.GET_TEACHERS_BLM_COMMENT_FAILED,
                    error
                });
                throw error;
            });
    };
}
export function getBLMStatus(blm, user) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return BlmAPI.getBLMStatus(blm.ID, blm.projectAssignmentID, user)
            .then((blmStatus) => {
                let isComplete;
                if (blmStatus === null) {
                    isComplete = false;
                } else {
                    isComplete = blmStatus.IsComplete;
                }
                dispatch({ type: types.GET_BLM_STATUS_SUCCESS, isComplete });
                return blm;
            })
            .catch((error) => {
                dispatch({ type: types.GET_BLM_STATUS_FAILED, error });
                throw error;
            });
    };
}

export function getBLMByID(blmID, user) {
    return function (dispatch, getstate) {
        dispatch(beginAjaxCall());
        return BlmAPI.getBLMByID(blmID, user)
            .then((blm) => {
                dispatch({ type: types.FETCH_BLM_SUCCESS, blm });
                return blm;
            })
            .catch((error) => {
                dispatch({ type: types.FETCH_BLM_FAILED, error });
                throw error;
            });
    };
}

export function getBLMItems(blmID, paID, user) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return BlmAPI.getBLMItems(blmID, paID, user)
            .then((blmItems) => {
                let originalBLMTemplate;
                // include the original blm template in the dispatch
                // determine what the template information is
                let fabricItemsStudent = {
                    Type: 1,
                    HasTemplate: false,
                    Content: {}
                };
                let fabricItemsTemplate = {};
                let fabricItems, blmData;

                forEach(blmItems, (bi) => {
                    bi.Content = JSON.parse(bi.Content);
                    if (bi.Type === 1) {
                        fabricItemsStudent = bi;
                    } else if (bi.Type === 2) {
                        fabricItemsTemplate = bi;
                        originalBLMTemplate = bi;
                    }
                });

                // If there is a template and there is not student template
                // That means a student has never worked on the template
                // we will add the attribute HasTemplate to determine viewer functionality
                // HasTemplate = students starting off a pre-existing creator template
                if (fabricItemsTemplate.ID && !fabricItemsStudent.ID) {
                    fabricItems = fabricItemsTemplate;
                    fabricItems.HasTemplate = true;
                    fabricItems.Type = 1;
                } else {
                    // either nothing from the api or something from the api and no template
                    fabricItems = fabricItemsStudent;
                }
                blmData = fabricItems.Content;

                dispatch({
                    type: types.GET_BLM_ITEMS_SUCCESS,
                    blmData,
                    fabricItems,
                    originalBLMTemplate
                });
                return blmItems;
            })
            .catch((error) => {
                dispatch({ type: types.GET_BLM_ITEMS_FAILED });
                throw error;
            });
    };
}

// We call getTemplateItems when we are in MODE_ADMIN_CREATE_TEMPLATE
// getTemplateItems grabs any previously made content with this particular blm

export function getTemplateItems(blmID, user) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return BlmAPI.getTemplateItems(blmID, user)
            .then((blmItems) => {
                let fabricItems = { Type: 2, HasTemplate: false, Content: {} };

                if (blmItems.length > 0 && blmItems[0].ID.length > 0) {
                    fabricItems.Content = JSON.parse(blmItems[0].Content);
                }

                let blmData = fabricItems.Content;
                dispatch({
                    type: types.GET_BLM_ITEMS_SUCCESS,
                    blmData,
                    fabricItems
                });
            })
            .catch((error) => {
                dispatch({ type: types.GET_BLM_ITEMS_FAILED });
                throw error;
            });
    };
}

export function getStudentsBLMItems(blmID, paID, studentID, user) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return BlmAPI.getStudentsBLMItems(blmID, paID, studentID, user)
            .then((blmItems) => {
                let blmData = JSON.parse(blmItems[0].Content);
                dispatch({ type: types.GET_BLM_ITEMS_SUCCESS, blmData });
            })
            .catch((error) => {
                dispatch({ type: types.GET_BLM_ITEMS_FAILED });
                throw error;
            });
    };
}

export function saveBLMItem(blmItem, user, showLoading) {
    return function (dispatch, getState) {
        if (showLoading) {
            dispatch(beginAjaxCall());
        }

        if (!getState().offlineQueue.isOnline || !navigator.onLine) {
            dispatch({
                type: 'OFFLINE_PROJECT_ITEM_SAVE',
                payload: {
                    action: 'saveBLMItem',
                    args: [blmItem, user, showLoading],
                    promise: {}
                },
                meta: {
                    queueIfOffline: true
                }
            });
            // Lets be optimistic and assume things will go well once we come back online!
            dispatch({
                type: types.SAVE_BLMITEM_FABRIC_SUCCESS,
                blmData: blmItem.Content
            });

            return Promise.resolve('OFFLINE');
        } else {
            return BlmAPI.saveBLMItem(blmItem, user)
                .then((blmItem) => {
                    let blmData = JSON.parse(blmItem.Content);
                    if (blmItem.Type === 1) {
                        dispatch({
                            type: types.SAVE_BLMITEM_FABRIC_SUCCESS,
                            blmData
                        });
                    } else {
                        throw blmItem;
                    }
                })
                .catch((error) => {
                    dispatch({ type: types.SAVE_BLMITEM_FABRIC_FAILED, error });
                    throw error;
                });
        }
    };
}

export function saveBLMTemplate(blmItem, user) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return BlmAPI.saveBLMTemplate(blmItem, user)
            .then((blmItem) => {
                let blmData = JSON.parse(blmItem.Content);
                dispatch({ type: types.SAVE_BLMITEM_FABRIC_SUCCESS, blmData }); // TODO why are we not dispatching here???
            })
            .catch((error) => {
                dispatch({ type: types.SAVE_BLMITEM_FABRIC_FAILED, error });
                throw error;
            });
    };
}

export function updateStatus(data, user) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        return BlmAPI.updateStatus(data, user)
            .then((updatedBlm) => {
                dispatch({
                    type: types.UPDATE_BLM_STATUS_SUCCESS,
                    isComplete: updatedBlm.IsComplete
                });
                return updatedBlm;
            })
            .catch((error) => {
                dispatch({ type: types.UPDATE_BLM_STATUS_FAILED });
                throw error;
            });
    };
}

export const setProjectReady = (ready) => ({
    type: types.SET_PROJECT_READY,
    ready
});
