import styled from 'styled-components';
import { Button, Navbar, Row } from 'react-bootstrap';

export const StyledButton = styled(Button)`
    &&& {
        ${(props) => props.css}
    }
`;

export const StyledNavbar = styled(Navbar)`
    &&& {
        ${(props) => props.css}
    }
`;

export const StyledDiv = styled.div`
    &&& {
        ${(props) => props.css}
    }
`;

export const StyledRow = styled(Row)`
    &&& {
        ${(props) => props.css}
    }
`;
