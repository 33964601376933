import * as types from '../actions/actionTypes';
import initialState from './initialState';

export function blmsReducer(state = initialState.blms, action) {
    switch (action.type) {
        case types.LOAD_BLMS_SUCCESS:
            return action.blms;

        default:
            return state;
    }
}

export function completedBLMsReducer(
    state = initialState.completedBLMs,
    action
) {
    switch (action.type) {
        case types.GET_COMPLETED_BLMS_SUCCESS:
            return action.completedBLMs;

        default:
            return state;
    }
}

export function blmReducer(state = initialState.blm, action) {
    switch (action.type) {
        case types.FETCH_BLM_SUCCESS:
            return action.blm;

        case types.GET_BLM_ITEMS_SUCCESS:
            return Object.assign({}, state, {
                blmData: action.blmData,
                fabricItems: action.fabricItems,
                originalBLMTemplate: action.originalBLMTemplate
            });

        case types.SAVE_TEACHERS_COMMENT_SUCCESS:
            return Object.assign({}, state, {
                teachersComment: action.teachersComment
            });

        case types.GET_TEACHERS_BLM_COMMENT_SUCCESS:
            return Object.assign({}, state, {
                teachersComment: action.teachersComment
            });

        case types.GET_BLM_STATUS_SUCCESS:
            return Object.assign({}, state, {
                isComplete: action.isComplete
            });

        case types.SAVE_BLMITEM_FABRIC_SUCCESS:
            return Object.assign({}, state, {
                blmData: action.blmData
            });

        case types.UPDATE_STUDENT_ITEM_SUCCESS:
            return Object.assign({}, state, {
                fabricItemsStudent: action.fabricItemsStudent
            });
        case types.UPDATE_TEMPLATE_ITEM_SUCCESS:
            return Object.assign({}, state, {
                fabricItemsTemplate: action.fabricItemsTemplate
            });

        case types.UPDATE_BLM_STATUS_SUCCESS:
            return Object.assign({}, state, {
                isComplete: action.isComplete
            });

        case types.REMOVE_BLM_DATA_SUCCESS:
            return action.blm;
        case types.SET_PROJECT_READY:
            return { ...state, projectIsReady: action.ready };

        case types.USER_LOGOUT_SUCCESS:
            return initialState.blms;

        default:
            return state;
    }
}
