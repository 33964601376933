import React from 'react';
import { Col, FormControl, FormGroup, Row, FormLabel } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addClassCode, toggleClassCodeModal } from '../../actions/userActions';
import CommonModal from '../common/CommonModal';


interface Iprops {
    show: boolean;
    submit: typeof addClassCode;
    cancel: ()=> void;
    addCode?: string;
}

interface Istate {
    code: string;
}

class ClassCodeModal extends React.Component<Iprops, Istate> {
    constructor(props: Iprops) {
        super(props);
        this.state = {
            code: this.props.addCode || ''
        }
    }
    render() {
        return (
            <div className="add-class-code-modal">
                <CommonModal
                    className="common-modal"
                    title={`Add Class Code or Group`}
                    modalVisable={this.props.show}
                    cancelText="Cancel"
                    submitText="Add"
                    cancel={this.props.cancel}
                    onHide={this.props.cancel}
                    submit={(e: any)=>{e.preventDefault(); this.props.submit(this.state.code)}}
                >
                    <div>
                        <Row>
                            <Col xs={6}>
                                <FormGroup controlId="classCode">
                                <FormLabel>
                                    Class Code or Group
                                    </FormLabel>
                                    <FormControl 
                                    required
                                    maxLength={5}
                                    minLength={5}
                                    type="text"
                                    name="classCode"
                                    placeholder="*****" 
                                    value={this.state.code}
                                    onChange={(e)=>this.setState({code: e.currentTarget.value})}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>

                </CommonModal>
            </div>
        );
    }
}


function mapStateToProps(state: any, ownProps: any) {
    return {
        loading: state.ajaxCallsInProgress > 0,
        show: state.dashboard.showClassCodeModal
    };
}

export default connect(mapStateToProps, {
    submit: addClassCode,
    cancel: toggleClassCodeModal
})(ClassCodeModal);
