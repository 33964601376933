import * as types from './actionTypes';

export function beginAjaxCall() {
    return { type: types.BEGIN_AJAX_CALL };
}

export function endAjaxCall() {
    return { type: types.END_AJAX_CALL };
}

export function manualAjaxStart() {
    return function (dispatch) {
        dispatch(beginAjaxCall());
    };
}

export function manualAjaxEnd() {
    return function (dispatch) {
        dispatch(endAjaxCall());
    };
}
