import * as React from 'react';
import { ONLINE, OFFLINE } from 'redux-queue-offline';

export const NetworkListener = (Provider) => {
    return class NetworkListenerComponent extends React.Component {
        defaultProps = { store: {} };
        componentDidMount() {
            if (window && window.addEventListener) {
                window.addEventListener('online', this._onlineListener);
                window.addEventListener('offline', this._offlineListener);
            }
        }
        componentWillUnmount() {
            if (window && window.removeEventListener) {
                window.removeEventListener('online', this._onlineListener);
                window.removeEventListener('offline', this._offlineListener);
            }
        }
        render() {
            return React.createElement(Provider, this.props);
        }
        _onlineListener = () => {
            this.props.store.dispatch({
                type: ONLINE
            });
        };
        _offlineListener = () => {
            this.props.store.dispatch({
                type: OFFLINE
            });
        };
    };
};
