import { IinitialState } from '../reducers/index';
import initialState from '../reducers/initialState';

/*
 * Migration 1 runs when upgrading from 0 to 1
 */

const typedMigrations = {
    162: (state: IinitialState): IinitialState => {
        console.log('running migration 162', state.appVersion);
        try {
            const serializedState = localStorage.getItem(`state-dibs`);
            if (serializedState !== null) {
                console.log("migrating previous state")
                localStorage.removeItem('state-dibs');
                return {
                    ...JSON.parse(serializedState),
                    ...state
                };
            }
        } catch {
            console.log('no previous state');
        }
        const { downloadedBooks } = initialState;
        return {
            ...state,
            downloadedBooks
        };
    }
} as { [key: number]: (state: IinitialState) => IinitialState };

const untypedMigrations = typedMigrations as any;
export const migrations = untypedMigrations;
