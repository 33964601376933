import React, { Component } from 'react';
import {
    manualAjaxEnd,
    manualAjaxStart
} from '../../actions/ajaxStatusActions';
import { tokenLogin, userLogout } from '../../actions/userActions';

import { Container } from 'react-bootstrap';
import Loading from '../common/Loading';
import { Navbar } from 'react-bootstrap';
import { StyledNavbar } from '../../constants/styledComponents';
import { connect } from 'react-redux';
import constants from '../../constants/constants';
import { getBLMByID } from '../../actions/blmActions';
import { getBookByID } from '../../actions/bookActions';
import { hashHistory } from 'react-router';
import logo from '../../images/logo.png';
import { toastr } from 'react-redux-toastr';
import { updateViewerMode } from '../../actions/modeActions';
import { viewerModes } from '../../constants/viewerModes';

/*
  *** IMPORTANT ***
    - Tokens are only applicable for web deployments
    - (will not be used for iOS and Android)
  *** IMPORTANT ***
*/

class Token extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showLoading: true
        };

        this.theme = constants.themeProvider.activeTheme;
    }

    componentDidMount() {
        console.log('opening book with token login');
        // return;
        let params = this.props.location.query;
        if (!params || !params.token) {
            // fail
            this.setState({ showLoading: false });
            toastr.error(
                `Error`,
                `Missing token/bookID, unable to load book`,
                constants.toastrWarningOptions
            );
        } else {
            // console.log(params);
            this.doLogin(params);
        }
    }

    doLogin = (params) => {
        this.props
            .userLogout()
            .then(() => {
                return this.props.tokenLogin(params.token);
            })
            .then((user) => {
                console.log('***USER', user.LoginID);
                if (user.APIKey === null) {
                    toastr.error(
                        `Error`,
                        'Unable to load book.  Please contact support.  User missing API Key.',
                        constants.toastrWarningOptions
                    );
                    return;
                }
                // we need to account for multiple possibilities now...
                // if we have a bookID only, we should just load up the book
                if (params.viewerMode === viewerModes.MODE_GENERIC) {
                    return this.props
                        .getBookByID(params.bookID, user)
                        .then((bookSaved) => {
                            this.setState({ loading: false });
                            // hashHistory.push('/viewer/' + params.bookID);
                            hashHistory.push({
                                pathname: `/viewer`,
                                query: this.props.location.query
                            });
                        });
                    // TODO: error handling here?
                }
                // if we have a bookID and a studentID, we should load the book
                // and load the student's highlights
                else if (
                    params.viewerMode ===
                    viewerModes.MODE_TEACHER_VIEW_STUDENT_BOOK
                ) {
                    console.log(
                        'TOKEN: LOADING TEACHER VIEW STUDENT HIHGLIGHTS'
                    );
                    return this.props
                        .getBookByID(params.bookID, user)
                        .then((bookSaved) => {
                            this.setState({ loading: false });
                            hashHistory.push({
                                pathname: `/viewer`,
                                query: this.props.location.query
                            });
                        });
                    // TODO: error handling here?
                } else if (
                    params.viewerMode === viewerModes.MODE_TEACHER_CLASS_NOTES
                ) {
                    console.log('TOKEN: LOADING TEACHER VIEW CLASS NOTES');
                    return this.props
                        .getBookByID(params.bookID, user)
                        .then((bookSaved) => {
                            this.setState({ loading: false });
                            hashHistory.push({
                                pathname: `/viewer`,
                                query: this.props.location.query
                            });
                        });
                    // TODO: error handling here?
                } else if (
                    params.viewerMode === viewerModes.MODE_TEACHER_GROUP_NOTES
                ) {
                    console.log('TOKEN: LOADING TEACHER VIEW Group NOTES');
                    return this.props
                        .getBookByID(params.bookID, user)
                        .then((bookSaved) => {
                            this.setState({ loading: false });
                            hashHistory.push({
                                pathname: `/viewer`,
                                query: this.props.location.query
                            });
                        });
                    // TODO: error handling here?
                }
                // if we have a blmID, we should load up the blm in admin
                // create template mode
                else if (
                    params.viewerMode === viewerModes.MODE_ADMIN_CREATE_TEMPLATE
                ) {
                    // TODO: load admin create mode
                    return this.props
                        .getBLMByID(params.blmID, user)
                        .then((blmSaved) => {
                            this.setState({ loading: false });
                            hashHistory.push({
                                pathname: `/viewer`,
                                query: this.props.location.query
                            });
                        });
                } else if (
                    params.viewerMode === viewerModes.MODE_TEACHER_STUDENT_BLM
                ) {
                    return this.props
                        .getBLMByID(params.blmID, user)
                        .then((blmSaved) => {
                            this.setState({ loading: false });
                            // hashHistory.push(`/viewer/${blmSaved.BookID}/${params.blmID}`);
                            hashHistory.push({
                                pathname: `/viewer`,
                                query: this.props.location.query
                            });
                        });
                } else if (params.viewerMode === viewerModes.MODE_RESOURCE) {
                    return this.props
                        .getBookByID(params.bookID, user)
                        .then((bookSaved) => {
                            this.setState({ loading: false });
                            // hashHistory.push('/viewer/' + params.bookID);
                            hashHistory.push({
                                pathname: `/viewer`,
                                query: this.props.location.query
                            });
                        });
                } else if (params.viewerMode === viewerModes.MODE_STUDENT) {
                    hashHistory.push({
                        pathname: `/bag?searchMode=local&tag=allTags`,
                        query: ''
                    });
                }
            })
            .catch((error) => {
                this.setState({ showLoading: false });
                console.error(
                    `error logging in or loading book with token`,
                    error
                );
                let message = `Unable to login or load book with token`;
                if (error === undefined) {
                    message = `The provided login token is invalid.`;
                }
                toastr.error(`Error`, message, constants.toastrWarningOptions);
            });
    };

    render() {
        return (
            <Container className="content">
                <StyledNavbar
                    css={{
                        'border-bottom-color': this.theme.primaryBackground
                    }}
                >
                    {' '}
                    <Navbar>
                        <Navbar.Brand>
                            <img src={logo} alt="" />
                        </Navbar.Brand>
                    </Navbar>
                </StyledNavbar>
                <div className="row login-page">
                    <Loading show={this.state.showLoading} />
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.user,
        loading: state.ajaxCallsInProgress > 0
    };
};

export default connect(mapStateToProps, {
    userLogout,
    tokenLogin,
    getBookByID,
    getBLMByID,
    updateViewerMode,
    manualAjaxStart,
    manualAjaxEnd
})(Token);
