import localForage from 'localforage';
export default class BookFS {
    static fs = localForage.createInstance({ name: 'dibs-web' });

    static removeAll() {
        console.log('removing books');
        return BookFS.fs.clear();
    }

    static removeBook(isbn) {
        return BookFS.fs.iterate((value, key, index) => {
            if (key.search(isbn)) {
                BookFS.fs
                    .removeItem(key)
                    .then(() => console.log('removed a key', key));
            }
        });
    }

    /*
     * have we downloaded the file
     */
    static hasDirectory(directory) {
        return BookFS.fs
            .keys()
            .then((keys) => {
                return keys.find((key) => key.search(directory)) ? true : false;
            })
            .catch(() => {
                console.error('error trying to find directory', directory);
                return false;
            });
    }

    /* Check if we have already downloaded a book
     * return true or false
     */
    static hasBook({isbn, IsEPub}) {
        let testFileLocation = `books/${isbn}/1.html`;
        if (IsEPub){
            testFileLocation = `books/${isbn}.epub`
        } 
        return BookFS.fs
            .getItem(testFileLocation)
            .then((result) => {
                return result ? true : false;
            })
            .catch((err) => {
                console.log(
                    'book does not exist on file system,  ' + isbn,
                    err
                );
                return false;
            });
    }

    /* Save a new book to the file system */
    static saveBook(isbn, zippedBook) {
        const basePath = `books/${isbn}`;
        let filePromises = [];
        console.log('saving book to file system: ', `books/${isbn}`);
        zippedBook.forEach(function (relativePath, file) {
            // build directory and file names and get MIME type
            let pathArr = relativePath.split('/');
            const pathFileName = pathArr.pop();
            const pathDirName = pathArr.length ? pathArr.join('/') : false;
            // console.log("iterating over", pathDirName, pathFileName, isbn);
            const saveProm = file
                .async('blob', (update) => {
                    // console.log('update', update);
                })
                .then((content) => {
                    if (pathDirName) {
                        // console.log('writing file:  ', `${basePath}/${pathDirName}/${pathFileName}`, content );
                        return BookFS.fs.setItem(
                            `${basePath}/${pathDirName}/${pathFileName}`,
                            content
                        );
                    } else {
                        // console.log('writing file: ', `${basePath}/${pathFileName}`);
                        return BookFS.fs.setItem(
                            `${basePath}/${pathFileName}`,
                            content
                        );
                    }
                });
            // create the array of promises
            filePromises.push(saveProm);
        });
        return Promise.all(filePromises)
            .then((result) => {
                // console.log('promises complete', result);
                return true;
            })
            .catch((err) => {
                // handle the error
                console.error('Error saving book files to file system', err);
            });
    }
    /* Save a new book to the file system */
    static saveSpeech(isbn, zippedSpeech) {
        const basePath = `books/${isbn}/audio/default`;
        let filePromises = [];
        // console.log('saving speech to file system: ', `books/${isbn}`);
        zippedSpeech.forEach(function (relativePath, file) {
            // build directory and file names and get MIME type
            let pathArr = relativePath.split('/');
            const pathFileName = pathArr.pop();
            // console.log("iterating over", pathDirName, pathFileName, isbn);
            const saveProm = file
                .async('blob', (update) => {
                    // console.log('update', update);
                })
                .then((content) => {
                    // console.log('writing file: ', `${basePath}/${pathFileName}`);
                    return BookFS.fs.setItem(
                        `${basePath}/${pathFileName}`,
                        content
                    );
                });
            // create the array of promises
            filePromises.push(saveProm);
        });
        return Promise.all(filePromises)
            .then((result) => {
                // console.log('promises complete', result);
                return true;
            })
            .catch((err) => {
                // handle the error
                console.error('Error saving speech files to file system', err);
            });
    }

    /* Get fileEntry object for file */
    static getBookFileEntry(isbn, relativePath) {
        if (!relativePath || !isbn) {
            console.error('missing path to file', isbn, relativePath);
        }
        // console.log(`getting book file entry, isbn: ${isbn}, path: ${relativePath}`, `books/${isbn}/${relativePath}`);
        return BookFS.fs
            .getItem(`books/${isbn}/${relativePath}`)
            .then((fileEntry) => {
                if (fileEntry === null) {
                    console.error(
                        'unable to find file',
                        `books/${isbn}/${relativePath}`
                    );
                    return Promise.reject('unable to find file');
                }
                return fileEntry.text();
            });
    }

    /* Get filesystem url for file
     */
    static getBookFileUrl(isbn, relativePath) {
        return BookFS.fs
            .getItem(`books/${isbn}/${relativePath}`)
            .then((item) => {
                if (item === null) {
                    console.error(
                        'unable to find file for url',
                        `books/${isbn}/${relativePath}`
                    );
                    return Promise.reject('unable to find file');
                }
                return window.URL.createObjectURL(item);
            })
            .catch((err) => {
                console.error('Error getting file, sending 404...', err);
                return 404;
            });
    }

    static getFileUrl(isbn, relativePath) {
        return BookFS.fs
            .getItem(`books/${isbn}/${relativePath}`)
            .then((item) => {
                return window.URL.createObjectURL(item);
            });
    }
}
