import config from '../api/config';

/*
 * load state localStorage
 */
export const loadState = (key) => {
    var statePromise = new Promise((resolve, reject) => {
        try {
            const serializedState = localStorage.getItem(`state-${key}`);
            if (serializedState === null) {
                resolve(undefined);
            } else {
                resolve(JSON.parse(serializedState));
            }
        } catch (error) {
            console.error('Error loading state', error);
            resolve(undefined);
        }
    });
    return statePromise;
};

/*
 * Save state
 */
export const saveState = (key, state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(`state-${key}`, serializedState);
    } catch (error) {
        // ignore write error
        console.error('Error saving state', error);
    }
};
